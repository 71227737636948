import React from 'react';
import Layout from "../components/layout";
import {Container} from "theme-ui";

export default function Agents() {
  return (
    <Layout>
      <Container sx={styles.container}>
        <h3 style={styles.title}>
          Get free buyer leads
        </h3>
        <p>
          Get up to $2000 in commissions for writing offers
        </p>
        <h4 style={styles.subtitle}>
          Want to know more?
        </h4>
        <p>
          Send an email to <a href={"mailto:agents@xdomu.com"}>agents@xdomu.com</a> for more information or to subscribe to the service.
        </p>
      </Container>
    </Layout>
  )
}

const styles = {
  container: {
    textAlign: 'center',
    a: {
      transition: '500ms',
      '&:hover': {
        color: 'primary',
      },
    },
  },
  title: {
    color: 'black',
    fontWeight: 'bold',
    lineHeight: [1.39],
    letterSpacing: ['-.7px', '-1.5px'],
    width: ['100%'],
    maxWidth: ['100%', null, null, '90%', '100%', '540px'],
    fontSize: '55px',
    marginBottom: '20px',
    textAlign: 'center',
    marginTop: '200px',
  },
  subtitle: {
    marginTop: '80px',
    marginBottom: '10px',
    textAlign: 'center',
  },
};